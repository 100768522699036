import { PageHeader, Space } from 'antd';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Filters, IssueDetail, IssuesTable } from './components';
import { IssuesContext } from './context/issues.context';

const Issues = ({ params }: { params: URLSearchParams }) => {
    const {
        loading,
        data,
        item,
        total,
        paginator,
        fetchData,
        onPageChange,
        searchParams,
        startDate,
        endDate,
        setItem,
        fetchItem,
        handleDate,
        handleIssueId,
        handleBooking,
        handleWorkspace,
        handleStatus,
        handleUnRead,
        handleType,
    } = useContext(IssuesContext);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fetchData();
    }, [params, paginator]);

    useEffect(() => {
        const { search } = location;
        const searchParams = new URLSearchParams(search);

        if (searchParams.has('open')) {
            const issueID = searchParams.get('open');
            issueID && fetchItem(issueID);
        }
    }, [location]);

    return (
        <PageHeader
            title="Issues"
            style={{ padding: '32px 24px 24px 0' }}
            footer={(
                <Space direction="vertical" size={24} style={{ width: '100%' }}>
                    {
                        item && (
                            <IssueDetail
                                item={item}
                                open={!!item}
                                loading={loading}
                                onClose={() => {
                                    setItem(null);
                                    navigate('/v2/issues');
                                }}
                            />
                        )
                    }
                    <Filters
                        searchParams={searchParams}
                        startDate={startDate}
                        endDate={endDate}
                        handleDate={handleDate}
                        handleStatus={handleStatus}
                        handleIssueId={handleIssueId}
                        handleBooking={handleBooking}
                        handleWorkspace={handleWorkspace}
                        handleUnRead={handleUnRead}
                        handleType={handleType}
                    />
                    <IssuesTable
                        loading={loading}
                        data={data}
                        total={total}
                        paginator={paginator}
                        onPageChange={onPageChange}
                    />
                </Space>
            )}
        />
    );
};

export default Issues;
